<template>
  <div class="wallet" style="padding-top: 44px;">
    <back-header :title="$t('wallet')">
      <router-link class="withdraw" to="/withdraw">{{$t('withdraw')}}</router-link>
    </back-header>
    <div class="head">
      <div class="diamond">
        <p>{{$t('diamond')}}</p>
        <span>{{ userInfo.diamond_total }}</span>
      </div>
      <i></i>
      <div class="diamond">
        <p>{{$t('avl_diamond')}}</p>
        <span>{{ userInfo.diamond }}</span>
      </div>
    </div>
    <div class="input">
      <label>{{$t('withdraw_account')}}</label>
      <input v-model.number="diamond" type="number" :placeholder="$t('enter_avl')">
    </div>
    <div class="input" style="border: none;">
      <label>{{$t('ava_account')}}</label>
      <span>PHP{{ rateMoney }}</span>
    </div>
    <h4>{{$t('my_withdraw')}} <router-link to="/my/account">{{$t('manage_account')}}</router-link></h4>
    <router-link v-if="!userInfo.is_anchor" to="/my/verify" class="verify">
      <p>{{$t('not_ver')}}</p>
      <span>{{$t('now_done')}}<van-icon name="arrow" /></span>
    </router-link>
    <template v-else>
      <p class="account" style="margin-top: 28px"><span>{{$t('ali_account')}}：</span>{{ account.alipay_account }}</p>
      <p class="account"><span>{{$t('ali_name')}}：</span>{{ account.alipay_name }}</p>
    </template>
    <div class="divider">
      <van-divider style="color: #333;">{{$t('withdraw_rul')}}</van-divider>
    </div>
    <div class="tips">
      1.{{$t('rul1')}}<br>
      2.{{$t('rul2')}}<br>
      3.{{$t('rul3')}}。<br>
      4.{{$t('rul4')}}。
    </div>
    <div class="btn" @click="withdraw">{{$t('now_withdraw')}}</div>
  </div>
</template>

<script>
import BackHeader from '../../components/back-header/back-header'
import { mapState } from 'vuex'
import { post } from '../../common/js/http'

export default {
  name: 'wallet',
  components: {
    BackHeader
  },
  data() {
    return {
      diamond: '',
      account: {}
    }
  },
  computed: {
    ...mapState(['userInfo', 'appConfig']),
    rateMoney() {
      return (Number(this.diamond) / this.appConfig.exchange_rate).toFixed(2)
    }
  },
  created() {
    this.getAccount()
  },
  methods: {
    withdraw() {
      if (this.rateMoney < this.appConfig.withdraw_min) {
        this.$toast.fail(`${this.$t('withdraw_cant_low')}${this.appConfig.withdraw_min}`)
        return
      }
      if (!this.account) {
        this.$toast.fail(this.$t('first_ver'))
        return
      }
      post('/webapi/withdraw/withdraw', {
        cash: this.rateMoney,
        diamond: this.diamond,
        alipay_account: this.account.alipay_account,
        alipay_name: this.account.alipay_name
      })
    },
    getAccount() {
      post('/webapi/withdraw/getAccount')
        .then(({ data: { data } }) => {
          this.account = data
        })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../common/style/variable.styl"
.wallet
  width 100%
  .withdraw
    display inline-block
    padding-right 30px
    font-size 30px
    color #070707
  .head
    margin 0 30px
    margin-top 30px
    height 259px
    background $theme-color
    display flex
    align-items center
    .diamond
      flex 1
      text-align center
      &>p
        margin-bottom 25px
        font-size 30px
        color #FFFFFF
      &>span
        font-size 40px
        font-weight bold
        color #FFFFFF
    &>i
      width 1px
      height 117px
      background #FFFFFF
  .input
    margin 0 30px
    display flex
    height 129px
    align-items center
    border-bottom 1px solid #E1E1E1
    &>label
      margin-right 31px
      font-size 32px
      color #333333
    &>input
      height 40px
      line-height 40px
      font-size 30px
    &>span
      font-weight bold
      font-size 32px
      color #18D5D9
  &>h4
    display flex
    align-items center
    justify-content space-between
    padding 0 30px
    padding-top 59px
    border-top 15px solid #F4F4F4
    font-size 32px
    color #333333
    &>a
      width 138px
      height 48px
      line-height 48px
      border-radius 24px
      border 1px solid $theme-color
      text-align center
      font-size 30px
      color $theme-color
  .verify
    margin 0 30px
    margin-top 65px
    display flex
    justify-content space-between
    font-size 30px
    &>p
      color #333
    &>span
      color #A2A2A2
  .account
    margin 0 30px
    margin-bottom 37px
    font-size 30px
    color #000
    &>span
      color #9C9C9C
  .divider
    margin 0 135px
    margin-top 72px
    margin-bottom 32px
  .tips
    margin 0 30px
    margin-bottom 82px
    line-height 35px
    font-size 24px
    color #B1B1B1
  .btn
    margin 0 62px
    margin-bottom 63px
    margin-top 47px
    height 100px
    line-height 100px
    border-radius 50px
    background $theme-color
    font-size 32px
    color #FEFFFF
    text-align center
</style>
